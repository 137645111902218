export const tableCol = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 130
  },
  {
    prop: 'productCategory',
    label: '原型分类',
    slotName: 'CategorySlot',
    minWidth: 80
  },
  {
    prop: 'struct',
    label: '款式',
    slotName: 'structSlot',
    minWidth: 100
  },
  {
    prop: 'sex',
    label: '适用人群',
    slotName: 'sexSlot',
    width: 80
  },
  {
    prop: 'season',
    label: '适用季节',
    slotName: 'seasonSlot',
    width: 80
  },
  {
    prop: 'sizeList',
    label: '尺码',
    minWidth: 200,
    slotName: 'sizesSlot'
  },
  {
    prop: 'referSize',
    label: '参考尺码',
    slotName: 'baseSizesSlot',
    minWidth: 100
  },
  {
    prop: 'createTime',
    label: '创建日期',
    slotName: 'createTimeSlot',
    minWidth: 100
  },
  {
    prop: 'ownerName',
    label: '负责人',
    minWidth: 100
  },
  {
    prop: 'operation',
    label: '操作',
    slotName: 'operationSlot',
    minWidth: 100,
    align: 'center'
  }
]
export const tableCol1 = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 200
  },
  {
    label: '原型分类',
    slotName: 'CategorySlot',
    minWidth: 150
  },
  {
    prop: 'styleList',
    label: '款式',
    slotName: 'structSlot',
    minWidth: 150
  },
  {
    prop: 'sizeList',
    label: '尺码',
    minWidth: 150,
    slotName: 'sizesSlot'
  },
  {
    prop: 'operation',
    label: '操作',
    slotName: 'operationSlot',
    minWidth: 120
  }
]
